<div>
  <div class="modal-header">{{ headerLabel | translate }}
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHtml]="infoLabel | translate"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="confirm btn btn-{{brandColor}}" (click)="close()" aria-label="Confirm" ngbAutofocus>
      <i *ngIf="confirmIcon" class="{{confirmIcon}}"></i> {{ confirmLabel | translate }}
    </button>
  </div>
</div>
