<div class="d-flex justify-content-between">
    <div>
        <ds-type-badge class="d-inline-block" *ngIf="showLabel" [object]="dso"></ds-type-badge>
        <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
            <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
               [routerLink]="[itemPageRoute]" class="lead item-list-title"
               [innerHTML]="dsoTitle"></a>
            <span *ngIf="linkType == linkTypes.None" class="lead item-list-title"
                  [innerHTML]="dsoTitle"></span>
            <span class="text-muted">
      <ds-truncatable-part [id]="dso.id" [minLines]="1">
        <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
          (<span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher" [innerHTML]="firstMetadataValue('dc.publisher') + ', '"></span>
          <span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
        </ng-container>
        <span *ngIf="dso.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0" class="item-list-authors">
          <span *ngFor="let author of dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
              <!-- <span [innerHTML]="author"><span [innerHTML]="author"></span></span> -->
            <ds-metadata-link-view [item]="dso" [metadata]="author"></ds-metadata-link-view>
            <span *ngIf="!last">; </span>
          </span>
        </span>
      </ds-truncatable-part>
    </span>
            <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
                <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                        [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
                </ds-truncatable-part>
            </div>
        </ds-truncatable>
        <ds-metric-badges *ngIf="!hideMetrics" class="d-block clearfix" [item]="dso"></ds-metric-badges>
    </div>
    <div class="align-self-center">
        <div>
            <ds-metric-donuts *ngIf="!hideMetrics" [item]="dso"></ds-metric-donuts>
        </div>
    </div>
</div>


