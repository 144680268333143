<div [ngClass] = "countersSection.style" class="ds-counters-container">
    <div class = "row d-flex justify-content-center ds-counters-container">
        <div *ngIf="isLoading$ | async">
            <ds-loading message="{{'loading.default' | translate}}"></ds-loading>
        </div>
        <div *ngFor="let counter of (counterData$ | async)"
             class="d-flex flex-column ds-counters-item">
            <div (click)="goToLink(counter.link)"
                 [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}"
                 class="ds-counters-item-icon col d-flex justify-content-center text-center align-items-center">
                <i [ngClass]="counter.icon"></i>
            </div>
            <div (click)="goToLink(counter.link)"
                 [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}"
                 class="ds-counters-item-label col d-flex justify-content-center text-center align-items-center">
                {{'explore.counters-section.' + counter.label | translate}}
            </div>
            <div (click)="goToLink(counter.link)"
                 [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}"
                 class="ds-counters-item-count col d-flex justify-content-center text-center align-items-center">
                <b>{{counter.count}}</b>
            </div>
        </div>
    </div>
</div>
