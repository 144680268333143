import { Action } from '@ngrx/store';
import { type } from '../../shared/ngrx/type';

/**
 * The list of MyDspaceOptimizationAction type definitions
 */
export const MyDspaceOptimizationActionTypes = {
  INIT: type('my-dspace-optimization/ADD'),
};

/* tslint:disable:max-classes-per-file */
/**
 * An ngrx action to initialize the MyDspaceOptimizationState
 */
export class MyDspaceOptimizationInitAction implements Action {
  type = MyDspaceOptimizationActionTypes.INIT;
  payload: {
    searchResults: any;
    mappedItems: { [uuid: string]: any };
    mappedTasks: { [uuid: string]: any };
  };

  /**
   * Create a new MyDspaceOptimizationInitAction
   */
  constructor(searchResults: any, mappedItems: { [uuid: string]: any }, mappedTasks: { [uuid: string]: any }) {
    this.payload = { searchResults, mappedItems, mappedTasks };
  }
}

/* tslint:enable:max-classes-per-file */

/**
 * A type to encompass all MyDspaceOptimizationAction
 */
export type MyDspaceOptimizationAction
  = MyDspaceOptimizationInitAction
  ;
