import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyDSpaceActionsComponent } from '../mydspace-actions';
import { ItemDataService } from '../../../core/data/item-data.service';
import { Item } from '../../../core/shared/item.model';
import { NotificationsService } from '../../notifications/notifications.service';
import { RequestService } from '../../../core/data/request.service';
import { SearchService } from '../../../core/shared/search/search.service';
import { AuthorizationDataService } from '../../../core/data/feature-authorization/authorization-data.service';
import { tap } from 'rxjs/operators';
import { RelationshipService } from '../../../core/data/relationship.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { BehaviorSubject } from 'rxjs';
import { ProcessTaskResponse } from '../../../core/tasks/models/process-task-response';
import { DSpaceObject } from '../../../core/shared/dspace-object.model';
import { RemoteData } from '../../../core/data/remote-data';
import { MyDspaceService } from '../../../my-dspace-page/my-dspace.service';
import { getItemPageRoute } from '../../../item-page/item-page-routing-paths';

/**
 * This component represents mydspace actions related to Item object.
 */
@Component({
  selector: 'ds-item-actions',
  styleUrls: ['./item-actions.component.scss'],
  templateUrl: './item-actions.component.html',
})

export class ItemActionsComponent extends MyDSpaceActionsComponent<Item, ItemDataService> implements OnInit {

  /**
   * The Item object
   */
  @Input() object: Item;

  /**
   * Route to the item's page
   */
  itemPageRoute: string;

  canUpdate: boolean = null;

  canWithdraw: boolean = null;

  canReinstate: boolean = null;

  public processingAction$ = new BehaviorSubject<boolean>(false);

  public processingUpdate$ = new BehaviorSubject<boolean>(false);

  public processingWithdraw$ = new BehaviorSubject<boolean>(false);

  public processingReinstate$ = new BehaviorSubject<boolean>(false);

  /**
   * Initialize instance variables
   *
   * @param {Injector} injector
   * @param {Router} router
   * @param {NotificationsService} notificationsService
   * @param {TranslateService} translate
   * @param {SearchService} searchService
   * @param {RequestService} requestService
   */
  constructor(protected injector: Injector,
              protected router: Router,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected authorizationService: AuthorizationDataService,
              protected relationshipService: RelationshipService,
              protected searchService: SearchService,
              protected requestService: RequestService,
              protected notificationService: NotificationsService,
              protected translateService: TranslateService,
              protected myDspaceService: MyDspaceService) {
    super(Item.type, injector, router, notificationsService, translate, searchService, requestService);
  }

  ngOnInit(): void {
    this.initPageRoute();
  }

  /**
   * Init the target object
   *
   * @param {Item} object
   */
  initObjects(object: Item) {
    this.object = object;
    this.initPageRoute();
  }

  /**
   * Initialise the route to the item's page
   */
  initPageRoute() {
    this.itemPageRoute = getItemPageRoute(this.object);
  }

  canBeCorrected(): Observable<boolean> {
    if (this.canUpdate !== null) {
      return of(this.canUpdate);
    }
    return this.myDspaceService.canBeCorrected(this.object).pipe(tap((canBeCorrected) => {
      this.canUpdate = canBeCorrected;
    }));
  }

  canBeWithdrawn(): Observable<boolean> {
    if (this.canWithdraw !== null) {
      return of(this.canWithdraw);
    }

    if (this.object.isWithdrawn) {
      this.canWithdraw = false;
      return of(this.canWithdraw);
    }

    return this.myDspaceService.canBeWithdrawn(this.object).pipe(tap((canBeWithdrawn) => {
      this.canWithdraw = canBeWithdrawn;
    }));
  }

  canBeReinstate(): Observable<boolean> {
    if (this.canReinstate !== null) {
      return of(this.canReinstate);
    }

    if (!this.object.isWithdrawn) {
      this.canReinstate = false;
      return of(this.canReinstate);
    }

    return this.myDspaceService.canBeReinstate(this.object).pipe(tap((canBeReinstate) => {
      this.canReinstate = canBeReinstate;
    }));
  }

  update() {
    this.processingUpdate$.next(true);
    this.processingAction$.next(true);
    this.myDspaceService.update(this.object)
      .subscribe((submissionObject) => {
        this.processingUpdate$.next(false);
        this.processingAction$.next(false);
        this.canWithdraw = false;
        this.canUpdate = false;
        this.canReinstate = false;
        this.router.navigate(['/workspaceitems/' + submissionObject.id + '/edit']);
      });
  }

  withdraw() {
    this.processingWithdraw$.next(true);
    this.processingAction$.next(true);
    this.myDspaceService.withdraw(this.object)
      .subscribe(() => {
        this.processingWithdraw$.next(false);
        this.processingAction$.next(false);
        this.canWithdraw = false;
        this.canUpdate = false;
        this.canReinstate = false;
        this.notificationService.success(this.translateService.get('submission.workflow.generic.withdraw.success'));
      });
  }

  reinstate() {
    this.processingReinstate$.next(true);
    this.processingAction$.next(true);
    this.myDspaceService.reinstate(this.object)
      .subscribe(() => {
        this.processingReinstate$.next(false);
        this.processingAction$.next(false);
        this.canWithdraw = false;
        this.canUpdate = false;
        this.canReinstate = false;
        this.notificationService.success(this.translateService.get('submission.workflow.generic.reinstate.success'));
      });
  }

  actionExecution(): Observable<ProcessTaskResponse> {
    return undefined;
  }

  reloadObjectExecution(): Observable<RemoteData<DSpaceObject> | DSpaceObject> {
    return undefined;
  }

}
