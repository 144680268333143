<ngx-charts-pie-chart
  [view]="view"
  [results]="(chartData | async)"
  [animations]="animations"
  [legend]="legend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [labels]="showLabels"
  (select)="onSelect($event)">
</ngx-charts-pie-chart>
