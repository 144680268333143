<ng-container *ngIf="initialized">

  <button *ngIf="hasNotDuplication"
          type="button"
          [className]="'btn btn-success'"
          ngbTooltip="{{helpLabelPrefix | translate}}"
          [disabled]="processing$ | async"
          (click)="submitTask()">
    <span *ngIf="processing$ | async"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.tasks.generic.processing' | translate}}</span>
    <span *ngIf="!(processing$ | async)"> <i class="fa fa-thumbs-up"></i> {{labelPrefix | translate}} </span>
  </button>

</ng-container>
