<div style="width: 100%">
<ng-container [formGroup]="group">

  <div role="group" [formGroupName]="model.id"
                    [id]="id"
                    [class.d-none]="model.hidden"
                    [ngClass]="getClass('element','control')">
     <ds-dynamic-form-control-container *ngFor="let _model of model.group"
                                       [formGroup]="group"
                                       [formModel]="formModel"
                                       [group]="control"
                                       [hasErrorMessaging]="model.hasErrorMessages"
                                       [hidden]="_model.hidden"
                                       [layout]="formLayout"
                                       [model]="_model"
                                       [templates]="templates"
                                       [ngClass]="[getClass('element', 'host', _model), getClass('grid', 'host', _model)]"
                                       (dfBlur)="onBlur($event)"
                                       (dfChange)="onChange($event)"
                                       (dfFocus)="onFocus($event)"
                                       (ngbEvent)="onCustomEvent($event, null, true)"></ds-dynamic-form-control-container>
  </div>
</ng-container>
</div>
