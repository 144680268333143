<div>
  <div class="filters py-2">
    <ds-search-facet-selected-option
      *ngFor="let value of (selectedValues$ | async)" [selectedValue]="value"
      [filterConfig]="filterConfig" [selectedValues$]="selectedValues$" [inPlaceSearch]="inPlaceSearch">
    </ds-search-facet-selected-option>
    <ng-container *ngFor="let page of (filterValues$ | async)?.payload">
      <div [@facetLoad]="animationState">
        <ds-search-facet-option
          *ngFor="let value of page.page; trackBy: trackUpdate" [filterConfig]="filterConfig"
          [filterValue]="value" [selectedValues$]="selectedValues$" [inPlaceSearch]="inPlaceSearch">
        </ds-search-facet-option>
      </div>
    </ng-container>
    <div class="clearfix toggle-more-filters">
        <div class="clearfix toggle-more-filters">
            <a (click)="showMore()" (keyup.enter)="showMore()" *ngIf="!(isLastPage$ | async)" class="float-left" tabindex="0">
                {{"search.filters.filter.show-more" | translate}}
            </a>
            <a (click)="showFirstPageOnly()" (keyup.enter)="showFirstPageOnly()" *ngIf="(currentPage | async) > 1" class="float-right" tabindex="0">
                {{"search.filters.filter.show-less" | translate}}
            </a>
        </div>
    </div>
    <ds-filter-input-suggestions [suggestions]="(filterSearchResults | async)"
                          [placeholder]="'search.filters.filter.' + filterConfig.name + '.placeholder' | translate"
                          [label]="'search.filters.filter.' + filterConfig.name + '.label' | translate"
                          [action]="currentUrl"
                          [name]="filterConfig.paramName"
                          [(ngModel)]="filter"
                          (submitSuggestion)="onSubmit($event)"
                          (clickSuggestion)="onClick($event)"
                          (findSuggestions)="findSuggestions($event)"
                          ngDefaultControl></ds-filter-input-suggestions>
</div>
