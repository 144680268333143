<ds-mydspace-request-type [submissionObject]="workflowitem$ | async"></ds-mydspace-request-type>
<br/>
<button type="button"
        *ngIf="!(isInstitutionRejectionTask() | async)"
        class="btn btn-info mt-1 mb-3"
        ngbTooltip="{{'submission.workflow.tasks.pool.claim_help' | translate}}"
        [disabled]="(processing$ | async)"
        (click)="claim()">
  <span *ngIf="(processing$ | async)"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.tasks.generic.processing' | translate}}</span>
  <span *ngIf="!(processing$ | async)"><i class="fas fa-hand-paper"></i> {{'submission.workflow.tasks.pool.claim' | translate}}</span>
</button>
<button type="button"
        *ngIf="(isInstitutionRejectionTask() | async)"
        class="btn btn-danger mt-1 mb-3"
        ngbTooltip="{{'submission.workflow.tasks.pool.reject_help' | translate}}"
        [disabled]="(processing$ | async)"
        (click)="claim()">
  <span *ngIf="(processing$ | async)"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.tasks.generic.processing' | translate}}</span>
  <span *ngIf="!(processing$ | async)"><i class="fas fa-trash"></i> {{'submission.workflow.tasks.pool.reject' | translate}}</span>
</button>
