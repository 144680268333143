import { Injectable } from '@angular/core';
import { AuthorizationDataService } from '../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../core/data/feature-authorization/feature-id';
import { getFirstSucceededRemoteDataPayload, getFirstSucceededRemoteListPayload } from '../core/shared/operators';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { mergeMap, take, tap } from 'rxjs/operators';
import { RelationshipService } from '../core/data/relationship.service';
import { Item } from '../core/shared/item.model';
import { map } from 'rxjs/internal/operators/map';
import { followLink } from '../shared/utils/follow-link-config.model';
import { forkJoin } from 'rxjs';
import { Authorization } from '../core/shared/authorization.model';
import { hasValue } from '../shared/empty.util';
import { RelationshipTypeService } from '../core/data/relationship-type.service';
import { RelationshipType } from '../core/shared/item-relationships/relationship-type.model';
import { SubmissionService } from '../submission/submission.service';
import { SubmissionObject } from '../core/submission/models/submission-object.model';
import { WorkflowStepDataService } from '../core/submission/workflowstep-data.service';
import { PoolTask } from '../core/tasks/models/pool-task-object.model';
import { TaskObject } from '../core/tasks/models/task-object.model';
import { Store } from '@ngrx/store';
import { MyDspaceOptimizationInitAction } from '../core/my-dspace-optimization/my-dspace-optimization.actions';
import { find } from 'lodash';
import { FindListOptions } from '../core/data/request.models';

export const getDspaceOptimizationState = (state: any) => state.core['mydspace/optimization'];

interface ResolvedAuthorization {
  objectHref: string;
  featureName: string;
  stale: boolean;
}

const MY_DSPACE_CACHED_FEATURES = [FeatureID.CanCorrectItem, FeatureID.CanWithdrawItem, FeatureID.CanReinstateItem];
/**
 * Service that performs optimized computations in order to improve performances of the my-dspace-page.
 */
@Injectable({ providedIn: 'root' })
export class MyDspaceService {

  // local cache
  private searchResults: any;
  private mappedItems: { [uuid: string]: any } = {};
  private mappedTasks: { [uuid: string]: any } = {};
  private resolvedAuthorizations: { [objectHref: string]: { [featureName: string]: boolean }} = {};
  private relationshipTypes: RelationshipType[];

  constructor(protected authorizationService: AuthorizationDataService,
              protected relationshipService: RelationshipService,
              protected relationshipTypeService: RelationshipTypeService,
              protected submissionService: SubmissionService,
              protected workflowStepService: WorkflowStepDataService,
              protected store: Store<any>) {
    this.store.select(getDspaceOptimizationState).subscribe((state) => {
      this.searchResults = state?.searchResults;
      this.mappedItems = state?.mappedItems;
      this.mappedTasks = state?.mappedTasks;
    });
  }

  /**
   * Cached data retrieved from the MyDspace searchResults are stored in the Store
   * in order make them available in an SSR deployment via rehydration.
   * @param searchResults
   */
  public initializeFromSearchResults(searchResults: any) {
    const mappedItems = this.mapItems(searchResults);
    const mappedTasks = this.mapTasks(searchResults);
    // this.resolvedAuthorizations = null;
    this.store.dispatch(new MyDspaceOptimizationInitAction(searchResults, mappedItems, mappedTasks));
  }

  /**
   * Prefetching data required for the perucris processing of mydspace search results. <br>
   * This activity must be performed during the my-dspace-page initialization. <br>
   */
  public prefetchData(): Observable<any> {
    if (this.relationshipTypes) {
      return of(null);
    }
    return this.relationshipTypeService.findAll({ currentPage: 1, elementsPerPage: 9999 })
      .pipe(getFirstSucceededRemoteListPayload(), tap((relationshipTypes) => {
        this.relationshipTypes = relationshipTypes;
      }));
  }

  /**
   * This activity must be performed after results parsing. <br>
   */
  public initializeResults(): Observable<any> {
    return this.resolveAuthorizations().pipe(
      take(1),
      tap((resolvedAuthorizations: { [objectHref: string]: { [featureName: string]: boolean }}) => {
        // this.resolvedAuthorizations = resolvedAuthorizations;
      })
    );
  }

  /**
   * Check whether the item can be corrected.
   * @param item
   */
  public canBeCorrected(item: Item): Observable<boolean> {
    return this.itemHasPendingRequest(item).pipe(
      switchMap((hasPendingRequest) => {
        if (hasPendingRequest) {
          return of(false);
        }
        return this.itemIsAuthorizedImpl(FeatureID.CanCorrectItem, item.self).pipe(take(1));
      })
    );
  }

  /**
   * Check whether the item can be withdrawn.
   * @param item
   */
  public canBeWithdrawn(item: Item): Observable<boolean> {
    return this.itemHasPendingRequest(item).pipe(
      switchMap((hasPendingRequest) => {
        if (hasPendingRequest) {
          return of(false);
        }
        return this.itemIsAuthorizedImpl(FeatureID.CanWithdrawItem, item.self).pipe(take(1));
      })
    );
  }

  /**
   * Check whether the item can be reinstate.
   * @param item
   */
  public canBeReinstate(item: Item): Observable<boolean> {
    return this.itemHasPendingRequest(item).pipe(
      switchMap((hasPendingRequest) => {
        if (hasPendingRequest) {
          return of(false);
        }
        return this.itemIsAuthorizedImpl(FeatureID.CanReinstateItem, item.self).pipe(take(1));
      })
    );
  }

  /**
   * Check whether the item is part of the given relationship label.
   * @param item
   */
  public itemHasRelation(item: Item, relationshipLabel: string): Observable<boolean> {
    return this.itemHasRelationImpl(item, relationshipLabel);
  }

  /**
   * Create an Item Correction submission.
   * @param item
   */
  public update(item: Item): Observable<SubmissionObject> {
    return this.submissionService.createSubmissionByItem(item.uuid, 'isCorrectionOfItem')
      .pipe(
        tap(() => {
          this.setItemStale(item.self);
        })
      );
  }

  /**
   * Create an Item Withdraw submission.
   * @param item
   */
  public withdraw(item: Item): Observable<SubmissionObject[]> {
    return this.submissionService.createSubmissionByItem(item.uuid, 'isWithdrawOfItem')
      .pipe(
        mergeMap((submissionObject) => this.submissionService.depositSubmission(submissionObject._links.self.href)),
        tap(() => {
          this.setItemStale(item.self);
        })
      );
  }

  /**
   * Create an Item Reinstate submission.
   * @param item
   */
  public reinstate(item: Item): Observable<SubmissionObject[]> {
    return this.submissionService.createSubmissionByItem(item.uuid, 'isReinstatementOfItem')
      .pipe(
        mergeMap((submissionObject) => this.submissionService.depositSubmission(submissionObject._links.self.href)),
        tap((submissionObject) => {
          this.setItemStale(item.self);
        })
      );
  }

  /**
   * Check whether the poolTask is an institution rejection task.
   */
  isInstitutionRejectionTask(poolTask: PoolTask): Observable<boolean> {
    return this.getTaskIdImpl(poolTask)
      .pipe(
        map((workflowStepId) => workflowStepId === 'waitForConcytecStep')
      );
  }

  // ===============
  //  @ Private
  // ===============

  private getRelationshipTypes(relationship: string): Observable<RelationshipType[]> {
    return this.prefetchData()
      .pipe(
        map(() => this.relationshipTypes.filter(
          (relationshipType: RelationshipType) => relationshipType.leftwardType === relationship || relationshipType.rightwardType === relationship))
      );
  }

  private itemHasPendingRequest(item: Item): Observable<boolean> {
    return this.itemHasRelation(item, 'isCorrectedByItem').pipe(
      switchMap((hasCorrectionRelation) => {
        if (hasCorrectionRelation) {
          return of(true);
        }
        return this.itemHasRelation(item, 'isWithdrawnByItem').pipe(
          switchMap((hasWithdrawRelation) => {
            if (hasWithdrawRelation) {
              return of(true);
            }
            return this.itemHasRelation(item, 'isReinstatedByItem');
          })
        );
      })
    );
  }

  // ================
  // Cache hit logic
  // ================

  /**
   * If the item is present in the local cache, it exploit this information to efficiently compute the result.
   * Otherwise it proceed with the standard implementation.
   */
  private itemHasRelationImpl(item: Item, relationshipLabel: string) {
    return this.getRelationshipTypes(relationshipLabel).pipe(
      switchMap((relationshipType: RelationshipType[]) => {

        // 1. try from local cache
        if (this.isItemInLocalCache(item.self)) {
          const itemFromLocalCache = this.getItemFromLocalCache(item.self);
          const rel = find(itemFromLocalCache._embedded.relationships._embedded.relationships,
            (relationship) => {
              return hasValue(find(relationshipType,
                (rt) => { return rt._links.self.href === relationship._links.relationshipType.href; }));
            });
          return of(hasValue(rel));
        }

        // 2. fallback to rest requests
        return of(false);
        // return this.relationshipService.getItemRelationshipsByLabel(item, relationshipLabel).pipe(
        //   getFirstSucceededRemoteDataPayload(),
        //   take(1),
        //   map((value: PaginatedList<Relationship>) => value.totalElements !== 0 )
        // );
      })
    );
  }

  /**
   * If the item is present in the local cache, it exploit this information to efficiently compute the result.
   * Otherwise it proceed with the standard implementation.
   */
  private itemIsAuthorizedImpl(featureId: FeatureID, objectUrl: string): Observable<boolean> {

    // 1. try from local cache
    if (this.isItemInLocalCache(objectUrl) && this.resolvedAuthorizations) {
      // console.log('AuthYes', objectUrl, featureId);
      const authorized = this.resolvedAuthorizations[objectUrl] && this.resolvedAuthorizations[objectUrl][featureId];
      return of(authorized);
    }
    // console.log('AuthNo', objectUrl, featureId);
    // 2. fallback to rest requests
    return this.authorizationService.isAuthorized(FeatureID.CanWithdrawItem, objectUrl).pipe(take(1));
  }

  /**
   * If the item is present in the local cache, it exploit this information to efficiently compute the result.
   * Otherwise it proceed with the standard implementation.
   */
  private getTaskIdImpl(taskObject: TaskObject): Observable<string> {
    // 1. try from local cache
    if (this.isTaskInLocalCache(taskObject.self)) {
      const taskFromLocalCache = this.getTaskFromLocalCache(taskObject.self);
      return of(taskFromLocalCache._embedded.step.id);
    }

    // 2. fallback to rest requests
    return this.workflowStepService.findByHref(taskObject._links.step.href).pipe(
      getFirstSucceededRemoteDataPayload(),
      map((workflowStep) => workflowStep.id)
    );
  }




  // ===============
  // Cache impl
  // ===============

  private mapItems(searchResults: any): { [uuid: string]: any } {
    const mapped = { ...this.mappedItems };
    searchResults._embedded.objects
      .map((searchResult) => searchResult._embedded.indexableObject)
      .forEach((indexableObject) => {
        if (indexableObject.type === 'workflowitem' || indexableObject.type === 'workspaceitem') {
          mapped[indexableObject._embedded.item._links.self.href] = indexableObject._embedded.item;
        }
        if (indexableObject.type === 'pooltask' || indexableObject.type === 'claimedtask') {
          mapped[indexableObject._embedded.workflowitem._embedded.item._links.self.href] = indexableObject._embedded.workflowitem._embedded.item;
        }
        if (indexableObject.type === 'item') {
          mapped[indexableObject._links.self.href] = indexableObject;
        }
      });
    return mapped;
  }

  private mapTasks(searchResults: any): { [uuid: string]: any } {
    const mapped = { ...this.mappedTasks };
    searchResults._embedded.objects
      .map((searchResult) => searchResult._embedded.indexableObject)
      .forEach((indexableObject) => {
        if (indexableObject.type === 'pooltask' || indexableObject.type === 'claimedtask') {
          mapped[indexableObject._links.self.href] = indexableObject;
        }
      });
    return mapped;
  }

  private searchAuthorizationsByObject(mappedItemKeys: string[], page: number = 1, previousPagesResultArray: Authorization[] = []): Observable<Authorization[]> {
    const paginationConfig = Object.assign(new FindListOptions(), {
      currentPage: page
    });

    return this.authorizationService.searchByObjects(MY_DSPACE_CACHED_FEATURES, mappedItemKeys,
      null, paginationConfig, true, true,
      followLink('feature'), followLink('object')).pipe(
      getFirstSucceededRemoteDataPayload(),
      switchMap((payload) => {
        const itemArray = [...previousPagesResultArray, ...payload.page];
        return payload.currentPage < payload.totalPages ?
          this.searchAuthorizationsByObject(mappedItemKeys, payload.currentPage + 1, itemArray) : of(itemArray);
      })
    );
  }

  private resolveAuthorizations(): Observable<any> {

    // items to calculate authorization are the ones relative to the searchResults.
    const mappedItems = this.mapItems(this.searchResults);

    // bisogna mantenere quanto meno i vecchi risultati.
    // fare la ricerca sui mappedItems senza ancora resolved authorization.


    const searchAuthorizations$ = Object.keys(mappedItems).length === 0
      ? of([])
      : this.searchAuthorizationsByObject(Object.keys(mappedItems));

    return searchAuthorizations$
      .pipe(
        switchMap((authorizations: Authorization[]) => {
          if (authorizations.length === 0) {
            return of([]);
          }
          return forkJoin(authorizations.map((authorization) => this.resolveAuthorization(authorization)));
        }),
        tap((resolvedAuthorizations: ResolvedAuthorization[]) => {
          // resolved authorizations must be inserted in the local cache
          resolvedAuthorizations.forEach((resolvedAuthorization) => {
            let itemAuthorizedFeatures = this.resolvedAuthorizations[resolvedAuthorization.objectHref];
            if (!itemAuthorizedFeatures) {
              itemAuthorizedFeatures = {};
            }
            itemAuthorizedFeatures[resolvedAuthorization.featureName] = true;
            this.resolvedAuthorizations[resolvedAuthorization.objectHref] = itemAuthorizedFeatures;
          });
        })
      );
  }

  private resolveAuthorization(authorization: Authorization): Observable<ResolvedAuthorization> {
    return authorization.object.pipe(getFirstSucceededRemoteDataPayload(), switchMap((object) => {
      return authorization.feature.pipe(getFirstSucceededRemoteDataPayload(), map((feature) => {
        return {
          objectHref: object.self, featureName: feature.id, stale: false
        };
      }));
    }));
  }

  private setItemStale(itemHref: string) {
    this.resolvedAuthorizations[itemHref] = {};
    // this.resolvedAuthorizations = this.resolvedAuthorizations.map((resolvedAuthorization) => {
    //   if (resolvedAuthorization.objectHref === itemHref) {
    //     return { ...resolvedAuthorization, stale: true };
    //   }
    //   return { ...resolvedAuthorization };
    // });
    delete this.mappedItems[itemHref];
  }

  private isItemInLocalCache(itemHref: string) {
    const itemFromMap = this.getItemFromLocalCache(itemHref);
    return hasValue(itemFromMap);
  }

  private getItemFromLocalCache(itemHref: string): any {
    const itemFromMap = this.mappedItems[itemHref];
    return itemFromMap;
  }

  private isTaskInLocalCache(taskHref: string) {
    const taskFromMap = this.getTaskFromLocalCache(taskHref);
    return hasValue(taskFromMap);
  }

  private getTaskFromLocalCache(taskHref: string): any {
    const taskFromMap = this.mappedTasks[taskHref];
    return taskFromMap;
  }

}
