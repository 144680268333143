<div class="container">

    <div class="table-responsive">
        <table class="table table-borderless">
            <thead>
            <tr>
                <th *ngFor="let column of getColumns()" [ngClass]="column.style">{{ 'explore.index.' + column.titleKey | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let topObject of ( topObjects | async ); let last=last"
                [class.border-bottom]="!last">
                <td *ngFor="let column of getColumns()" [ngClass]="column.style">
                    <a [routerLink]="getItemPage(topObject)">{{getColumnValue(topObject,column)}}</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>



