import { MyDspaceOptimizationAction, MyDspaceOptimizationActionTypes } from './my-dspace-optimization.actions';

export interface ResolvedAuthorization {
  objectHref: string;
  featureName: string;
  stale: boolean;
}

export interface MyDspaceOptimizationState {
  searchResults: any;
  mappedItems: { [uuid: string]: any };
  mappedTasks: { [uuid: string]: any };
  resolvedAuthorizations: ResolvedAuthorization[];
}

// Object.create(null) ensures the object has no default js properties (e.g. `__proto__`)
const initialState: MyDspaceOptimizationState = Object.create(null);

export function myDspaceOptimizationReducer(state = initialState, action: MyDspaceOptimizationAction): MyDspaceOptimizationState {
  switch (action.type) {

    case MyDspaceOptimizationActionTypes.INIT: {
      return {
        ...state,
        searchResults: action.payload.searchResults,
        mappedItems: action.payload.mappedItems,
        mappedTasks: action.payload.mappedTasks
      };
    }

    default: {
      return state;
    }
  }
}
