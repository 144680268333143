    <div class="container search-section">
    <h2 *ngIf="searchSection.displayTitle">{{ 'explore.search-section.' + sectionId + '.title' | translate }}</h2>
    <form *ngIf="searchSection.searchType !== 'basic' || !searchSection.searchType"
          class="container m-0"
          [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)">
        <div formArrayName="queryArray" *ngFor="let queryStatement of queryArray.controls; let i = index; let isLast = last">
            <div [formGroupName]="i" class="test-query-item row">

                <div class="filter mb-2 col-md-3 px-md-0">
                    <select name="filter" id="filter" formControlName="filter"
                            class="form-control custom-select">
                        <option *ngFor="let filter of (filters | async)" [value]="filter">{{'explore.index.' + filter | translate}}</option>
                    </select>
                </div>

                <div class="query mb-2 col-md-7 px-md-2">
                    <input type="text" name="query" id="query" formControlName="query"
                           class="form-control"/>
                </div>

                <div *ngIf="!isLast"  class="operation mb-2 col-md-2 px-md-0">
                    <select name="operation" id="operation" formControlName="operation"
                            class="form-control">
                        <option *ngFor="let operation of operations" [value]="operation">{{operation}}</option>
                    </select>
                </div>

                <div *ngIf="isLast" class="add mb-2 col-md-2 px-md-0">
                    <button id="addButton" type="button"
                            class="btn btn-light"
                            (click)="addQueryStatement()">+</button>
                </div>


            </div>
        </div>
        <div class="row">
            <div class="col-md-3 px-md-0"></div>
            <div class="actions col-md-7 px-md-2 d-flex justify-content-end">
                <button type="button" id="resetButton" class="btn btn-outline-light mr-2" (click)="onReset()"><strong>{{'explore.search-section.reset-button' | translate}}</strong></button>
                <button type="submit" id="searchButton" class="btn btn-light">
                    <strong>{{'explore.search-section.search-button' | translate}}
                        <i class="fa fa-search"></i>
                    </strong>
                </button>
            </div>
            <div class="col-md-2 px-md-0">
                <div>
                    <div *ngIf="searchSection.searchType !== 'basic' || !searchSection.searchType"
                         (click)="openHelpModal()" class="btn btn-outline-light" id="search-section-help-btn">
                        {{helpModalLabelPrefix + 'open-button' | translate}}
                        <i class="fa fa-info-circle"></i>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="col-md-12 d-flex justify-content-center" *ngIf="searchSection.searchType === 'basic'">
        <div class="col-md-8">
            <ds-search-form *ngIf="searchSection.searchType === 'basic'" [inPlaceSearch]="false"
                            [searchPlaceholder]="'search.search-form.placeholder' | translate"> ></ds-search-form>
        </div>
    </div>
</div>
