<div class="container">
  <ds-suggestions-notification></ds-suggestions-notification>
  <ds-my-dspace-new-submission *dsShowOnlyForRole="[roleTypeEnum.Submitter]"></ds-my-dspace-new-submission>
  <div class="my-4 d-flex justify-content-end">
    <button class="btn btn-outline-primary mt-1 ml-2" type="button" *ngIf="canAccessSubsistema() | async"
            [attr.aria-label]="'mydspace.subsistema-btn.tooltip' | translate"
            title="{{'mydspace.subsistema-btn.tooltip' | translate}}"
            (click)="toSubsistema()">

      <i class="fa fa-university" aria-hidden="true"></i>
      {{'mydspace.subsistema-btn.label' | translate}}
    </button>
    <button class="btn btn-outline-primary mt-1 ml-2" type="button" *ngIf="showCommunityListLink() | async"
            [attr.aria-label]="'mydspace.communities-btn.tooltip' | translate"
            title="{{'mydspace.communities-btn.tooltip' | translate}}"
            (click)="toCommunityList()">

      <i class="fa fa-university" aria-hidden="true"></i>
      {{'mydspace.communities-btn.label' | translate}}
    </button>
    <button class="btn btn-outline-primary mt-1 ml-2" type="button"
            [attr.aria-label]="'mydspace.processes' | translate"
            title="{{'mydspace.processes' | translate}}"
            (click)="toProcesses()">

      <i class="fa fa-terminal" aria-hidden="true"></i>
      {{'mydspace.processes' | translate}}
    </button>
  </div>
  <div class="search-page row">
      <ds-search-sidebar *ngIf="!(isXsOrSm$ | async)" class="col-3 sidebar-md-sticky"
                         id="search-sidebar"
                         [configurationList]="(configurationList$ | async)"
                         [resultCount]="(resultsRD$ | async)?.payload?.totalElements"
                         [viewModeList]="viewModeList"
                         [searchOptions]="(searchOptions$ | async)"
                         [sortOptions]="(sortOptions$ | async)"
                         [refreshFilters]="refreshFilters.asObservable()"
                         [inPlaceSearch]="inPlaceSearch"></ds-search-sidebar>
      <div class="col-12 col-md-9">
          <div class="d-flex">
              <ds-search-form id="search-form" class="flex-grow-1"
                              [query]="(searchOptions$ | async)?.query"
                              [scope]="(searchOptions$ | async)?.scope"
                              [currentUrl]="getSearchLink()"
                              [showScopeSelector]="true"
                              [inPlaceSearch]="inPlaceSearch"
                              [searchPlaceholder]="'mydspace.search-form.placeholder' | translate">
              </ds-search-form>
              <ds-my-dspace-new-bulk-import></ds-my-dspace-new-bulk-import>
              <ds-item-export-modal-launcher [searchOptions$]="searchOptions$"></ds-item-export-modal-launcher>
          </div>
          <ds-search-labels [inPlaceSearch]="inPlaceSearch"></ds-search-labels>
          <div class="row">
              <div id="search-body"
                   class="row-offcanvas row-offcanvas-left w-100"
                   [@pushInOut]="(isSidebarCollapsed() | async) ? 'collapsed' : 'expanded'">
                  <ds-search-sidebar *ngIf="(isXsOrSm$ | async)" class="col-12"
                                     id="search-sidebar-sm"
                                     [configurationList]="(configurationList$ | async)"
                                     [resultCount]="(resultsRD$ | async)?.payload?.totalElements"
                                     (toggleSidebar)="closeSidebar()"
                                     [ngClass]="{'active': !(isSidebarCollapsed() | async)}"
                                     [searchOptions]="(searchOptions$ | async)"
                                     [sortOptions]="(sortOptions$ | async)"
                                     [refreshFilters]="refreshFilters.asObservable()"
                                     [inPlaceSearch]="inPlaceSearch">
                  </ds-search-sidebar>
                  <div id="search-content" class="col-12">

                      <div class="d-block d-md-none search-controls clearfix">
                          <ds-view-mode-switch [viewModeList]="viewModeList" [inPlaceSearch]="inPlaceSearch"></ds-view-mode-switch>
                          <button (click)="openSidebar()" aria-controls="#search-body"
                                  class="btn btn-outline-primary float-right open-sidebar"><i
                                  class="fas fa-sliders"></i> {{"search.sidebar.open"
                              | translate}}
                          </button>
                      </div>
                      <ds-my-dspace-results [searchResults]="resultsRD$ | async"
                                            [searchConfig]="searchOptions$ | async"
                                            [context]="context$ | async"
                                            (contentChange)="onResultsContentChange()"></ds-my-dspace-results>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
