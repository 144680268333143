<div class="pt-2" [ngClass]="{'border-top': !showErrorMessages, 'border border-danger': showErrorMessages}" [hidden]="model.hidden">
    <ds-form #formRef="formComponent" *ngIf="formModel" @shrinkInOut
             [formId]="formId"
             [formModel]="formModel"
             [displayCancel]="false"
             [displaySubmit]="false"
             [arrayButtonsStyle]="'inline-btn-group'"
             [isInlineGroupForm]="true"
             (dfBlur)="onBlur($event)"
             (dfChange)="onChange($event)"
             (dfFocus)="onFocus($event)"
             (ngbEvent)="onCustomEvent($event)"
             (removeArrayItem)="remove($event)"></ds-form>
</div>
