export const KEYBOARD_SHORTCUTS_LOGIN = 'l';
export const KEYBOARD_SHORTCUTS_LOGOUT = 'x';
export const KEYBOARD_SHORTCUTS_PROFILE = 'p';
export const KEYBOARD_SHORTCUTS_SIDEBAR_PIN = 'q';
export const KEYBOARD_SHORTCUTS_MYDSPACE = 'm';
export const KEYBOARD_SHORTCUTS_PUBLICSEARCH = 's';
export const KEYBOARD_SHORTCUTS_MYDSPACE_SEARCH_SWITCH = {
  'workspace': 'w',
  'workflow': 'a',
};
