<div *ngIf="((isAuthenticated | async) || isItem()) && contextMenuObject" ngbDropdown #itemOptions="ngbDropdown" placement="bottom-right" class="d-inline-block float-right">
  <button class="btn btn-secondary" id="context-menu" ngbDropdownToggle>
    {{ 'context-menu.actions.label' | translate }}
  </button>
  <div id="itemOptionsDropdownMenu" aria-labelledby="context-menu" ngbDropdownMenu>
    <h6 class="dropdown-header">{{'context-menu.actions.label' | translate}}</h6>
    <ng-container *ngFor="let entry of (getContextMenuEntries() | async)">
      <ng-container  *ngComponentOutlet="entry; injector: objectInjector;"></ng-container>
    </ng-container>
  </div>
</div>
