import { Group } from '../../../core/eperson/models/group.model';
import { forkJoin, Observable } from 'rxjs';
import {
  getFirstSucceededRemoteDataPayload,
  getFirstSucceededRemoteListPayload,
  getPaginatedListPayload
} from '../../../core/shared/operators';
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import { GroupDataService } from '../../../core/eperson/group-data.service';
import { Injectable } from '@angular/core';
import { DSONameService } from '../../../core/breadcrumbs/dso-name.service';
import { DsDynamicMultipleSelectModel } from '../../../shared/form/builder/ds-dynamic-form-ui/models/multiple-select/ds-dynamic-multiple-select.model';
import { DynamicCheckboxGroupModel, DynamicCheckboxModel, DynamicFormOption } from '@ng-dynamic-forms/core';
import { EPerson } from '../../../core/eperson/models/eperson.model';
import { CTI_VITAE_ROLE_REGEX } from '../../../core/eperson/eperson-data.service';
import { of } from 'rxjs/internal/observable/of';
import { AuthService } from '../../../core/auth/auth.service';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { hasNoValue, hasValue } from '../../../shared/empty.util';
import { environment } from '../../../../environments/environment';

export interface InstitutionalScopedRole {
  name: string;
  institutionalRole: Group;
  scopes: Group[];
  model: DsDynamicMultipleSelectModel<any>;
}




@Injectable({providedIn: 'root'})
export class PerucrisRolesService {

  constructor(private groupsDataService: GroupDataService,
              private nameService: DSONameService,
              private authService: AuthService) {
  }

  /**
   * Retrieve all the Institutional Roles (and Scopes) in Perucris.
   */
  public getInstitutionalRoles(): Observable<InstitutionalScopedRole[]> {
    return this.groupsDataService.searchGroups('INSTITUTIONAL:').pipe(
      getFirstSucceededRemoteListPayload(),
      switchMap((institutionalRoleGroups) => this.fetchInstitutionalRoles(institutionalRoleGroups))
    );
  }

  private fetchInstitutionalRoles(institutionalRoleGroups: Group[]): Observable<InstitutionalScopedRole[]> {
    return forkJoin(institutionalRoleGroups.map((institutionalRoleGroup: Group) =>
      this.groupsDataService.findAllByHref(institutionalRoleGroup._links.subgroups.href,
        { currentPage: 1, elementsPerPage: 9999 }
        ).pipe(
        getFirstSucceededRemoteDataPayload(),
        getPaginatedListPayload(),
        map((groups) => {
          const institutionalRole: InstitutionalScopedRole = {
            name: this.nameService.getName(institutionalRoleGroup),
            institutionalRole: institutionalRoleGroup,
            scopes: groups,
            model: null
          };
          return institutionalRole;
        })
      )
    ));
  }

  /**
   * For each InstitutionalScopedRole initialize form models given the existent roles of the eperson.
   * @param institutionalScopedRoles
   * @param formLayout
   * @param rolesNoAvailable
   * @param eperson
   */
  public initializedInstitutionalScopedRolesForm(
    institutionalScopedRoles: InstitutionalScopedRole[],
    formLayout,
    rolesNoAvailable,
    eperson): InstitutionalScopedRole[] {

    institutionalScopedRoles.forEach((institutionalScopedRole: InstitutionalScopedRole) => {
      const options = this.initDynamicFormOptions(institutionalScopedRole.scopes, rolesNoAvailable);
      const value = [];
      if (eperson != null) {
        const epersonRoles = eperson.allMetadata('perucris.eperson.institutional-scoped-role');
        for (const option of options) {
          for (const epersonRole of epersonRoles) {
            if ( option.value !== null && option.value.id === epersonRole.authority ) {
              value.push(option.value);
            }
          }
        }
      }
      const multipleSelectModel = new DsDynamicMultipleSelectModel({
        id: institutionalScopedRole.name.replace('.', '_'),
        label: institutionalScopedRole.name,
        name: institutionalScopedRole.name.replace('.', '_'),
        options,
        value,
      });
      institutionalScopedRole.model = multipleSelectModel;
      formLayout[institutionalScopedRole.name] = { grid: { host: 'row' } };
    });

    return institutionalScopedRoles.filter((isr) => {
      return !isr.name.includes(': ');
    });
  }

  /**
   * Return the list of the selected institutional roles.
   * @param institutionalScopedRoles
   */
  public getSelectedInstitutionalRoles(institutionalScopedRoles: InstitutionalScopedRole[]) {
    const roles = [];
    institutionalScopedRoles.forEach((institutionalScopedRole: InstitutionalScopedRole) => {
      if (institutionalScopedRole.model.value.length === 0) {
        return;
      }
      roles.push(new Object({
        value: institutionalScopedRole.name,
        authority: institutionalScopedRole.institutionalRole.id,
        confidence: 600
      }));
    });
    return roles;
  }

  /**
   * Return the list of the selected institutional scoped roles.
   * @param institutionalScopedRoles
   */
  public getSelectedInstitutionalScopedRoles(institutionalScopedRoles: InstitutionalScopedRole[]) {
    const roles = [];
    institutionalScopedRoles.forEach((institutionalScopedRole) => {
      institutionalScopedRole.model.value
        .forEach((value) => {
          roles.push(new Object({
            value: value.name,
            authority: value.id,
            confidence: 600
          }));
        });
    });
    return roles;
  }

  public initRoleValues(eperson: EPerson, roleMetadata: string) {
    const roleValues = {};
    eperson.allMetadata(roleMetadata)
      .forEach((metadata) => roleValues[metadata.authority] = true);
    return roleValues;
  }

  /**
   * Return true if
   *  - the user has the Concytec role and no specialGroups are present.
   *  - the user has the Concytec role and not empty specialGroups include the Concytec role.
   *
   * Return false otherwise
   *
   * N.B. specialGroups are retrieved from the JWT accessToken.sg
   */
  isConcytecUser(): Observable<any> {

    return this.authService.getAuthenticatedUserFromStore().pipe(switchMap((eperson) => {
      if (!eperson) {
        return of(false);
      }
      const epersonRoles = eperson.allMetadata('perucris.eperson.role');
      const concytecGroup = epersonRoles.find((role) => (role.authority === environment.concytecGroupUUID));

      // if has not concytecGroup
      if (hasNoValue(concytecGroup)) {
        return of(false);
      }

      // if there are specialGroups in the accessToken but not including the concytecGroup then return false
      const token = this.authService.getToken();
      const tokenClaims = jwtDecode<JwtPayload>(token.accessToken);
      const sg = (tokenClaims as any).sg;
      if (hasValue(sg) && !sg.includes(concytecGroup.authority)) {
        return of(false);
      }

      // true otherwise
      return of(true);
    }));
  }

  public initDynamicCheckboxModels(groups: Group[], rolesNoAvailable): DynamicCheckboxModel[] {

    const roleCheckboxModels = groups
      .filter( (group) => this.nameService.getName(group).match(CTI_VITAE_ROLE_REGEX) == null)
      .map( (group) =>
        new DynamicCheckboxModel({
            id: group.id,
            label: this.nameService.getName(group),
            value: false
          },
          {
            element: {
              control: 'btn-outline-info'
            }
          })
      );
    if ( roleCheckboxModels.length === 0) {
      roleCheckboxModels.push(new DynamicCheckboxModel({
        id: 'rolesNoAvailable',
        label: rolesNoAvailable,
        value: false,
        disabled: true
      }));
    }
    return roleCheckboxModels;
  }

  public getSelectedRoles(roles: DynamicCheckboxGroupModel) {
    return roles.group
      .filter((model) => model.value === true)
      .map((model) => new Object({
        value: model.label,
        authority: model.name,
        confidence: 600
      }));
  }

  private initDynamicFormOptions(groups: Group[], rolesNoAvailable): DynamicFormOption<any>[] {
    const options = groups.map( (group) =>
      new DynamicFormOption({
        label: this.nameService.getName(group),
        value: { id: group.id, name: this.nameService.getName(group)}
      })
    );
    if (options.length === 0) {
      options.push(new DynamicFormOption({
        label: rolesNoAvailable,
        value: null,
        disabled: true
      }));
    }
    return options;
  }


}
