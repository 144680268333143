<div *ngVar="(metadata$ | async) as metadata">
	<ng-container *ngIf="metadata" [ngTemplateOutlet]="!!metadata.authority ? withAuthority : withoutAuthority"
                  [ngTemplateOutletContext]="{metadata:metadata}"></ng-container>
</div>



<ng-template style="display:flex;" #withAuthority let-metadata="metadata">
  <a rel="noopener noreferrer"
     [routerLink]="['/items/' + metadata.authority]">
     <span>{{metadata.value}}</span>
     <i class="{{metadata.icon}}"></i>
  </a>
  <img *ngIf="metadata.orcidAuthenticated"
       placement="top"
       ngbTooltip="{{ metadata.orcidAuthenticated }}"
       class="orcid-icon"
       alt="orcid-logo"
       src="assets/images/orcid.logo.icon.svg"/>
</ng-template>

<ng-template #withoutAuthority let-ref="metadata">
  {{normalizeValue(metadata.value)}}
</ng-template>
