<button class="btn btn-primary mt-1 mb-3"
            ngbTooltip="{{'submission.workflow.generic.view-help' | translate}}"
            [routerLink]="[itemPageRoute]">
  <i class="fa fa-info-circle"></i> {{"submission.workflow.generic.view" | translate}}
</button>

<button
  ngbTooltip="{{'mydspace.update-btn.help' | translate}}"
  *ngIf="(canBeCorrected() | async)" class="btn btn-primary mt-1 mb-3" (click)="update()"
  [disabled]="(processingAction$ | async)">
  <span *ngIf="(processingUpdate$ | async)"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.generic.processing' | translate}}</span>
  <span *ngIf="!(processingUpdate$ | async)"><i class="fa fa-edit"></i> {{ 'mydspace.update-btn' | translate }}</span>
</button>

<button
  *ngIf="(canBeWithdrawn() | async)" 
  class="btn btn-danger mt-1 mb-3" 
  ngbTooltip="{{'submission.workflow.generic.withdraw-help' | translate}}" 
  [disabled]="(processingAction$ | async)"
  (click)="withdraw()">
  <span *ngIf="(processingWithdraw$ | async)"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.generic.processing' | translate}}</span>
  <span *ngIf="!(processingWithdraw$ | async)"><i class="fa fa-trash"></i> {{"submission.workflow.generic.withdraw" | translate}}</span>
</button>

<button
  *ngIf="(canBeReinstate() | async)" 
  class="btn btn-success mt-1 mb-3" 
  ngbTooltip="{{'submission.workflow.generic.reinstate-help' | translate}}" 
  [disabled]="(processingAction$ | async)"
  (click)="reinstate()">
  <span *ngIf="(processingReinstate$ | async)"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.generic.processing' | translate}}</span>
  <span *ngIf="!(processingReinstate$ | async)"><i class="fa fa-trash-restore"></i> {{"submission.workflow.generic.reinstate" | translate}}</span>
</button>
