<div class="col-12">
  <h2>{{ 'explore.facet-section.title' | translate }}</h2>
  <div class="row">
    <div *ngFor="let facet of (facets$ | async)" class="col-{{getFacetsBoxCol(facet)}} mb-4">
      <span class="facet-name">{{'explore.index.' + facet.name | translate}}</span>
      <div *ngIf="facet.filterType.includes('chart'); else notChartFacet">
        <ds-search-chart [filter]="facet" [inPlaceSearch]="false"> </ds-search-chart>
      </div>
      <ng-template #notChartFacet>
        <div *ngFor="let facetValue of facet._embedded.values" class="border p-3 facet-label">
          <a [routerLink]="[searchService.getSearchLink()]"
             [queryParams]="getSearchQueryParams(facet, facetValue)">
            <span class="test-label">{{facetValue.label}}</span>
          </a>
          <div class="pr-1">
            <span class="test-count badge badge-primary">{{facetValue.count}}</span>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</div>
