import { Component, Input } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Item } from '../../../../core/shared/item.model';
import { getFirstSucceededRemoteDataPayload } from '../../../../core/shared/operators';
import { SubmissionObject } from '../../../../core/submission/models/submission-object.model';
import { MyDspaceService } from '../../../../my-dspace-page/my-dspace.service';
import { hasNoValue } from '../../../empty.util';

@Component({
  selector: 'ds-mydspace-request-type',
  templateUrl: './mydspace-request-type.component.html',
})
export class MyDSpaceRequestTypeComponent {

  @Input()
  submissionObject: SubmissionObject;

  private newSubmissionRequest: boolean = null;
  private correctionRequest: boolean = null;
  private withdrawRequest: boolean = null;
  private reinstateRequest: boolean = null;

  constructor(
    protected myDspaceService: MyDspaceService) {
  }

  isNewSubmissionRequest(): Observable<boolean> {
    if (this.newSubmissionRequest !== null) {
      return of(this.newSubmissionRequest);
    }

    return combineLatest( [ this.isCorrectionRequest(), this.isWithdrawRequest(), this.isReinstateRequest()]).pipe(
      map(([isCorrection, isWithdraw, isReinstate]) => {
        this.newSubmissionRequest = !isCorrection && !isWithdraw && !isReinstate;
        return this.newSubmissionRequest;
      })
    );
  }

  isCorrectionRequest(): Observable<boolean> {
    if (this.correctionRequest !== null) {
      return of(this.correctionRequest);
    }

    return this.requestHasRelation('isCorrectionOfItem').pipe(
      tap((hasRelation) => {
        this.correctionRequest = hasRelation;
      })
    );
  }

  isWithdrawRequest(): Observable<boolean> {
    if (this.withdrawRequest !== null) {
      return of(this.withdrawRequest);
    }

    return this.requestHasRelation('isWithdrawOfItem').pipe(
      tap((hasRelation) => {
        this.withdrawRequest = hasRelation;
      })
    );
  }

  isReinstateRequest(): Observable<boolean> {
    if (this.reinstateRequest !== null) {
      return of(this.reinstateRequest);
    }

    return this.requestHasRelation('isReinstatementOfItem').pipe(
      tap((hasRelation) => {
        this.reinstateRequest = hasRelation;
      })
    );
  }

  requestHasRelation(relationship: string): Observable<boolean> {
    if (hasNoValue(this.submissionObject)) {
      return of(false);
    }
    return this.submissionObject.item.pipe(
      getFirstSucceededRemoteDataPayload(),
      switchMap((item: Item) => this.myDspaceService.itemHasRelation(item, relationship))
    );
  }

}
