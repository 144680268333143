<ngx-charts-line-chart
  [results]="(chartData | async)"
  [animations]="animations"
  [legend]="legend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  (select)="onSelect($event)">
</ngx-charts-line-chart>
